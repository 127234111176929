import React, { useState } from "react";
import axios from "axios";
import BizClass from "./OurPresence.module.scss";
import { ImLocation2 } from "react-icons/im";
import { AiTwotoneMail } from "react-icons/ai";
import { MdFileDownloadDone } from "react-icons/md";
import Footer from "../Footer/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Client_2 from "../../../Imges/Slac-client-02.png";
import Client_1 from "../../../Imges/client-01.png";
import Client_3 from "../../../Imges/client-03.png";
import Client_4 from "../../../Imges/client-04.png";
import Client_5 from "../../../Imges/client-05.png";

const OurPresence = () => {
  // Initialize formdata state
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
  });
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // https://api.app.mindspright.co.in/api/v2/website/create
      await axios.post("https://api.app.mindspright.co.in/api/v2/website/create", formdata);
      console.log("Email sent successfully");
      setFormdata({ name: "", email: "", phone: "", subject: "" });
      setShowPopup(true);
    } catch (error) {
      console.error("Failed to send email:", error);
    }
  };

  // Define location cards
  const location_Card = [
    {
      logo: <ImLocation2 />,
      address: "Mumbai Office Address:",
      info: (
        <div>
          712-714, 7th floor, C-Wing, <br />
          Trade World. <br />
          Kamala Mills Compound, <br />
          Senapati Bapat Marg, <br />
          Lower parel (West), <br />
          Mumbai-400013 <br />
          Phone: +91-22-42197000"
        </div>
      ),
    },
    // Other location cards...
  ];

  // Define client images
  const clientimg = [Client_1, Client_2, Client_3, Client_4, Client_5];

  // Slider configuration
  const Clientslider = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "Slider",
    centerMode: true,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="Biz_PageStart">
      <div className={BizClass.OurPresence}>
        {/* Location cards */}
        <div className={BizClass.Location_Card}>
          {location_Card.map((data, index) => (
            <div className={BizClass.Card} key={index}>
              <div className={BizClass.SVG}>{data.logo}</div>
              <div>
                <h4>{data.address}</h4>
                <span>{data.info}</span>
              </div>
            </div>
          ))}
        </div>

        {/* Form section */}
        <div className={BizClass.Form_Section}>
          <img
            src="https://mind.hawkcdn.in/wp-content/uploads/2019/12/contact-about1.png"
            alt=""
          />
          <div className={BizClass.Section}>
            <h4>Make a free consultation with our expert team</h4>
            <p>
              Need an instant solution to your legal problem? You can do it with
              Mindspright Legal
            </p>
            <form onSubmit={handleSubmit} className={BizClass.Form}>
              <input
                type="text"
                placeholder="Your Name*"
                name="name"
                value={formdata.name}
                onChange={(e) =>
                  setFormdata({ ...formdata, name: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Your Email*"
                name="email"
                value={formdata.email}
                onChange={(e) =>
                  setFormdata({ ...formdata, email: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Phone*"
                name="phone"
                value={formdata.phone}
                onChange={(e) =>
                  setFormdata({ ...formdata, phone: e.target.value })
                }
              />
              <textarea
                type="text"
                name="subject"
                value={formdata.subject}
                onChange={(e) =>
                  setFormdata({ ...formdata, subject: e.target.value })
                }
              ></textarea>
              <button type="submit">
                <MdFileDownloadDone /> Consult today
              </button>
            </form>
          </div>
        </div>
        <div className={BizClass.Slider_Conatiner}>
          <Slider {...Clientslider} className={BizClass.Slider}>
            {clientimg.map((data) => {
              return <>{data.img}</>;
            })}
          </Slider>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default OurPresence;
