import React from "react";
import BizClass from "./Careers.module.scss";
import Pattern from "./Imges/Pattern-01.png";
import Carrer_Banner from "./Imges/Careers_banner.jpg";
import { VscCloudUpload } from "react-icons/vsc";
import Footer from "../Footer/Footer";

const Careers = () => {
  return (
    <div className="Biz_PageStart">
      <div className={BizClass.Careers_Page}>
        <div className={BizClass.Section_Img}>
          <img src={Carrer_Banner} alt="" />
        </div>
        <div className={BizClass.Section_Two}>
          <div className={BizClass.Content}>
            <span>
              If you are interested in a career with us, as a lawyer, a member
              of our strategic and operational teams, or as an intern,{" "}
            </span>
            <h4>
              contact{" "}
              <a href="careers@mindspright.co.in">careers@mindspright.co.in</a>{" "}
              for opportunities
            </h4>
          </div>
          <div className={BizClass.Img}>
            <img src={Pattern} alt="" />
          </div>
        </div>
        {/* <div className={BizClass.Form}>
        <div className={BizClass.Personal_Info}>
          <h3>Your Personal Information</h3>
          <div className={BizClass.Form_Content}>
            <div className={BizClass.Data}>
              <label htmlFor="#">
                Your Name <span>*</span>
              </label>
              <div>
                <input type="text" placeholder="First Name" />
                <input type="text" placeholder="Last Name" />
              </div>
            </div>
            <div className={BizClass.Data}>
              <label htmlFor="#">
                Your Email Address <span>*</span>
              </label>
              <div>
                <input type="text" placeholder="Enter Your Email" />
                <input type="text" placeholder="Confirm Email" />
              </div>
            </div>
            <div className={BizClass.Data}>
              <label htmlFor="#">
                Your Phone <span>*</span>
              </label>
              <div>
                <input type="text" placeholder="Your Phone Number" />
              </div>
            </div>
          </div>
        </div>
        <div className={BizClass.Academic}>
          <h3>Academic</h3>
          <div className={BizClass.Form_Content}>
            <div className={BizClass.Data}>
              <label htmlFor="#">
                Law School <span>*</span>
              </label>
              <div>
                <input type="text" placeholder="Your Phone Number" />
              </div>
            </div>
            <div className={BizClass.Data}>
              <label htmlFor="#">
                Duration of Stud
                <span>*</span>
              </label>
              <div>
                <div>
                  <input type="radio" name="course" />
                  <span>3 Year Course</span>
                </div>
                <div>
                  <input type="radio" name="course" />
                  <span>5 Year Course</span>
                </div>
              </div>
            </div>
            <div className={BizClass.Data}>
              <label htmlFor="#">
                Year of Study
                <span>*</span>
              </label>
              <div>
                <div>
                  <input type="radio" name="Year" />
                  <span>II Year</span>
                </div>
                <div>
                  <input type="radio" name="Year" />
                  <span>III Year</span>
                </div>
              </div>
            </div>
            <div className={BizClass.Data}>
              <label htmlFor="#">
                CGPA
                <span>*</span>
              </label>
              <div>
                <input type="text" />
              </div>
            </div>
            <p>
              Please write your CGPA in the following format: “Your CGPA out of
              Total CGPA”. For example, “7.8 out of 10”.
            </p>
          </div>
        </div>
        <div className={BizClass.Document_Upload}>
          <h3>Document Upload</h3>
          <div className={BizClass.Form_Content}>
            <div className={BizClass.Data}>
              <label htmlFor="#">
                Why do you want to intern with us?
                <span>*</span>
              </label>
              <div>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="6"
                  placeholder="please tell us more about your self and why you would be the best candidate for this position"
                ></textarea>
              </div>
            </div>
            <div className={BizClass.Data}>
              <label htmlFor="#">Upload Your Curriculum Vitae</label>
              <div>
                <VscCloudUpload />
                <span>
                  {" "}
                  Drop files here, or <p>Browse</p>{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={BizClass.Submit_Button}>
          <button>Submit</button>
        </div>
      </div> */}
        <Footer />
      </div>
    </div>
  );
};

export default Careers;
