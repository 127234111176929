import React, { useState } from "react";
import BizClass from "./CorePracticies.module.scss";
import { FaAngleRight } from "react-icons/fa";
import Footer from "../Footer/Footer";
import { HiChevronDoubleRight } from "react-icons/hi";
import CorePracticiesSlider from "./CorePracticiesSlider/CorePracticiesSlider";
import { Link } from "react-router-dom";
const LitigationDispute = () => {
  const [activeMenu, setActiveMenu] = useState("2");
  return (
    <div className="Biz_PageStart">
      <div className={BizClass.Litigation_Section_Img}>
        <h4>Litigation & Dispute Resolution</h4>
      </div>
      <div className={BizClass.Section_Core}>
        <div className={BizClass.Section_Div}>
        <div className={BizClass.Links_Contant}>
            <Link
              className={
                activeMenu === "1" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Securities-law"
              onClick={() => setActiveMenu("1")}
            >
              Securities Law
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "2" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Litigation-Dispute"
              onClick={() => setActiveMenu("2")}
            >
              Litigation & Dispute Resolution
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "3" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/corporate-law"
              onClick={() => setActiveMenu("3")}
            >
              Corporate Law
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "4" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Compliances"
              onClick={() => setActiveMenu("4")}
            >
              Secretarial Compliances
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "5" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/white-collar-crime"
              onClick={() => setActiveMenu("5")}
            >
              White Collar Crime
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "6" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/intellectual-property"
              onClick={() => setActiveMenu("6")}
            >
              Intellectual Property
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "7" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/transaction-advisory"
              onClick={() => setActiveMenu("7")}
            >
              Transaction Advisory
              <FaAngleRight />
            </Link>
          </div>
          <div className={BizClass.Data_Contant}>
            <p>
              MSL has represented clients in all types of civil and commercial
              litigations across varied courts and tribunals across the country.
              Apart from the in house lawyers at MSL, our strong network of
              civil litigation attorneys around the country enables us to engage
              local lawyers and Advocates on Records (AOR) who can file
              petitions directly with the Supreme Court, and appear and/or plead
              before various High Courts, Tribunals, and other forums as well.
            </p>
            <h4>Some of the Practices of this group are:</h4>
            <div className={BizClass.Content_Div_Two}>
              <div>
                <HiChevronDoubleRight />
                Representing clients before, Supreme Court, various High Courts,
                Tribunals as well as other forums.
              </div>
              <div>
                <HiChevronDoubleRight />
                Providing services to various stakeholders in the insolvency
                matters and files/defends insolvency petitions before the NCLT
                and NCLAT.
              </div>
              <div>
                <HiChevronDoubleRight />
                Providing services in matters related to criminal litigation.
              </div>
            </div>
          </div>
        </div>
        <div className={BizClass.Slider_Div}>
          <CorePracticiesSlider />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LitigationDispute;
