import React, { useState } from "react";
import BizClass from "./CorePracticies.module.scss";
import { FaAngleRight } from "react-icons/fa";
import Footer from "../Footer/Footer";
import { HiChevronDoubleRight } from "react-icons/hi";
import CorePracticiesSlider from "./CorePracticiesSlider/CorePracticiesSlider";
import { Link } from "react-router-dom";
const IntellectualProperty = (props) => {
  const [activeMenu, setActiveMenu] = useState("6");
  return (
    <div className="Biz_PageStart">
      <div className={BizClass.Intellectual_Section_Img}>
        <h4>Intellectual Property</h4>
      </div>
      <div className={BizClass.Section_Core}>
        <div className={BizClass.Section_Div}>
        <div className={BizClass.Links_Contant}>
            <Link
              className={
                activeMenu === "1" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Securities-law"
              onClick={() => setActiveMenu("1")}
            >
              Securities Law
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "2" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Litigation-Dispute"
              onClick={() => setActiveMenu("2")}
            >
              Litigation & Dispute Resolution
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "3" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/corporate-law"
              onClick={() => setActiveMenu("3")}
            >
              Corporate Law
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "4" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Compliances"
              onClick={() => setActiveMenu("4")}
            >
              Secretarial Compliances
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "5" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/white-collar-crime"
              onClick={() => setActiveMenu("5")}
            >
              White Collar Crime
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "6" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/intellectual-property"
              onClick={() => setActiveMenu("6")}
            >
              Intellectual Property
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "7" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/transaction-advisory"
              onClick={() => setActiveMenu("7")}
            >
              Transaction Advisory
              <FaAngleRight />
            </Link>
          </div>
          <div className={BizClass.Data_Contant}>
            <p>
              MSL’s Intellectual Property (IP) practice group stems from the
              experiences and expertise of its partners and associates. MSL’s IP
              Practice ensures coverage of matters related to Trademark, Patent,
              Copyright, Design, Trade Secrets, Geographical Indication, Data
              Protection, etc.
            </p>
            <h4>Some of the services provided by this group are as follows:</h4>
            <div className={BizClass.Content_Div}>
              <div>
                <HiChevronDoubleRight />
                Advising clients in relation to the proper selection,
                classification and procedure to be chosen to safeguard their
                intellectual property.
              </div>
              <div>
                <HiChevronDoubleRight />
                Appearance before Courts and other Quasi-Judicial bodies of law,
                defending clients for or against IP breach cases.
              </div>

              <div>
                <HiChevronDoubleRight />
                Filing of applications for registration of any intellectual
                property.
              </div>
              <div>
                <HiChevronDoubleRight />
                IP due diligence and IP audits for companies going for
                Acquisitions, Mergers and Joint Ventures.
              </div>
              <div>
                <HiChevronDoubleRight />
                Drafting of documents related to Licensing & Franchising.
              </div>
            </div>
          </div>
        </div>
        <div className={BizClass.Slider_Div}>
          <CorePracticiesSlider />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IntellectualProperty;
