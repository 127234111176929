import React, { Component } from "react";
import Slider from "react-slick";
import BizClass from "../CorePracticies.module.scss";
import Swipper_Team_1 from "../../../../Imges/Swipper-Team-1.png";
import Swipper_Team_2 from "../../../../Imges/Swipper-Team-2.png";
import Swipper_Team_3 from "../../../../Imges/Swipper-Team-3.png";

export default class CorePracticiesSlider extends Component {
  render() {
    return (
      <div className={BizClass.Slider}>
        {sliderData.map((data, index) => {
          return (
            <>
              <div className={BizClass.Card_Div}>
                <div>{data.Img}</div>
              </div>
            </>
          );
        })}
      </div>
    );
  }
}

const sliderData = [
  {
    Img: <img src={Swipper_Team_1} class="swiper-slide-image" alt="" />,
  },
  {
    Img: <img src={Swipper_Team_2} alt="" />,
  },
  {
    Img: <img src={Swipper_Team_3} alt="" />,
  },
];
