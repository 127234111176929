import React, { useState } from "react";
import BizClass from "./Home.module.scss";
import New_Team_1 from "../../../Imges/Team-New-1.jpg";
import New_Team_2 from "../../../Imges/Team-New-2.jpg";
import { AiOutlineHeatMap, AiTwotoneStar } from "react-icons/ai";
import { RiDoubleQuotesR } from "react-icons/ri";
import { MdDiversity3 } from "react-icons/md";
import { SiSecurityscorecard } from "react-icons/si";
import Footer from "../Footer/Footer";
import About_US_Exp from "../../../Imges/About-Us-Exp.jpeg";
import Core_1 from "./../../../Imges/securities-law-1.jpg";
import Core_2 from "../../../Imges/Services-Banners.jpg";
import Core_3 from "../../../Imges/Corporate-Law-banner.jpg";
import Core_4 from "../../../Imges/White-Collar-Crime-Banner.jpg";
import { Link } from "react-router-dom";
import Core_5 from "../../../Imges/Intellectual-Property-Banner.jpg";
import Core_6 from "../../../Imges/Transaction-Advisory-Banner.jpg";
import About_US_Double from "../../../Imges/About-Us-Double.png";
import Client_Banner from "../../../Imges/Client_banner.png";
import CookieConsent from "react-cookie-consent";

const About_Us = () => {
  const card_data = [
    {
      svg: <SiSecurityscorecard />,
      head: "VERSATILE",
      data: "  With Securities and Finance at its core, a mid-tier firm, MSL also   has its footprints in the fields of Corporate Laws and Advisory     Services, Intellectual Property Laws, Litigation & Arbitration to  name a few",
    },
    {
      svg: <MdDiversity3 />,
      head: "DIVERSITY",
      data: "MSL houses talent from across India our Lawyers come with diverse back     grounds like Business Administration,   Policy Sciences, Company Secretaries,    Insolvency Professionals and Chartered     Accountants amongst others",
    },
    {
      svg: <AiOutlineHeatMap />,
      head: "NATIONWIDE",
      data: " Headquartered in the financial capital      of the country, Mumbai; we also have      offices in Delhi, Jaipur and Ranchi &     growing further",
    },
  ];
  return (
    <div className="Biz_PageStart">
      <div className={BizClass.About_Us}>
        <div className={BizClass.Section_Img}>
          <h4>Home</h4>
        </div>
        <div className={BizClass.Section_Two}>
          <div className={BizClass.Content}>
            <span>About us</span>
            <h4>At MSL We Believe, Every Case is Defendable</h4>
            <p>
              15 years young as an entity, with a cumulative experience of over
              a 100 years, our strengths lies in our roots and humble
              beginnings. Our core beliefs haven’t changed and we continue to
              strive to deliver better each and every time. Truly believeing
              that Honest efforts yield best results, in work and in law.
            </p>
          </div>
          <div className={BizClass.Img}>
            <img
              decoding="async"
              src={About_US_Exp}
              title="About-Us-Image"
              alt="About-Us-Image"
            />
          </div>
        </div>
        <div className={BizClass.Card_Section_Container}>
          {card_data.map((data, indx) => {
            return (
              <>
                <div className={BizClass.Card}>
                  {data.svg}
                  <h3>{data.head}</h3>
                  <span>{data.data}</span>
                </div>
              </>
            );
          })}
        </div>
        <div className={BizClass.Section_Three}>
          <div className={BizClass.Conatiner_Div}>
            <div className={BizClass.img}>
              <img src={About_US_Double} alt="" />
            </div>
            <div className={BizClass.Data}>
              {/* <div className={BizClass.Process_Div}>
              <div className={BizClass.details}>
                <span>Consultation</span>
                <div className={BizClass.Percentage_Div}>
                  <p>95%</p>
                  <AiFillCaretDown />
                </div>
                <div>
                  <div className={BizClass.Color_Div_First}></div>
                </div>
              </div>
              <div className={BizClass.details}>
                <span>Business Restructuring</span>
                <div className={BizClass.Percentage_Div}>
                  <p>90%</p>
                  <AiFillCaretDown />
                </div>
                <div>
                  <div className={BizClass.Color_Div_Second}></div>
                </div>
              </div>
              <div className={BizClass.details}>
                <span> Finance Advisory</span>
                <div className={BizClass.Percentage_Div}>
                  <p>93%</p>
                  <AiFillCaretDown />
                </div>
                <div>
                  <div className={BizClass.Color_Div_Third}></div>
                </div>
              </div>
            </div> */}
              <span>
                Established in 2008, Mindspright Legal has represented over 2000
                clients before SEBI and SAT. Listed year after year in
                Bloomberg’s league list of top 10 Indian Equity IPO Legal
                Advisors since 2011, Mindspright has also been listed on the
                Asia Pacific IFLR 1000 firm for its work in Financial Laws. With
                expertise in the Securities Law Practice, MSL has over the
                course of the last 15 years, gained significant recognition for
                its Corporate Advisory Services, Commercial Laws Practice,
                Regulatory Advisory, and Transactional Services, competing with
                the top tier names in the industry. MSL also provides services
                in the fields of Insolvency Laws, Mergers & Acquisition,
                Business Restructuring, General Litigation Practice, defending
                White Collar Crimes, Intellectual Property Laws, and Project
                Finance Advisory.
              </span>
            </div>
          </div>
        </div>
        <div className={BizClass.Caore_Practicies_Section}>
          <h4>Core Practicies</h4>
          <div className={BizClass.Conatainer}>
            <Link to="/securities-law" className={BizClass.Img_Data}>
              <div className={BizClass.IMG}>
                <img src={Core_1} alt="" />
              </div>
              <label> Securities Law</label>
            </Link>
            <Link to="/litigation-dispute" className={BizClass.Img_Data}>
              <div className={BizClass.IMG}>
                <img src={Core_2} alt="" />
              </div>
              <label> Litigation & Dispute Resolution</label>
            </Link>
            <Link to="/corporate-law" className={BizClass.Img_Data}>
              <div className={BizClass.IMG}>
                <img src={Core_3} alt="" />
              </div>
              <label> Corporate Law</label>
            </Link>
            <Link to="/white-collar-crime" className={BizClass.Img_Data}>
              <div className={BizClass.IMG}>
                <img src={Core_4} alt="" />
              </div>
              <label> White Collar Crime</label>
            </Link>
            <Link to="/intellectual-property" className={BizClass.Img_Data}>
              <div className={BizClass.IMG}>
                <img src={Core_5} alt="" />
              </div>
              <label> Intellectual Property</label>
            </Link>
            <Link to="/transaction-advisory" className={BizClass.Img_Data}>
              <div className={BizClass.IMG}>
                <img src={Core_6} alt="" />
              </div>
              <label> Transaction Advisory</label>
            </Link>
          </div>
        </div>
        <div className={BizClass.Testimonial}>
          <div className={BizClass.Heading}>
            <div className={BizClass.Left}>
              <span>Testimonial</span>
              <h4>
                It’s always a joy to hear <br /> what our team has to say!
              </h4>
            </div>
          </div>
          <div className={BizClass.Card_Section}>
            <div className={BizClass.Card}>
              <div className={BizClass.Top_Img}>
                <div className={BizClass.Img}>
                  <div className={BizClass.Logo}>
                    <RiDoubleQuotesR />
                  </div>
                  <img src={New_Team_1} alt="" />
                </div>
              </div>
              <div className={BizClass.Data}>
                <div className={BizClass.Review}>
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                </div>
                <span>
                  Working in Mindspright Legal has always been motivating,
                  thought provoking, unconventional and a true Spright to the
                  Mind{" "}
                </span>
                <h5>
                  MS. Nirali Mehta,<span>Partner Mindspright Legal</span>{" "}
                </h5>
              </div>
            </div>
            <div className={BizClass.Card}>
              <div className={BizClass.Top_Img}>
                <div className={BizClass.Img}>
                  <div className={BizClass.Logo}>
                    <RiDoubleQuotesR />
                  </div>
                  <img src={New_Team_2} alt="" />
                </div>
              </div>
              <div className={BizClass.Data}>
                <div className={BizClass.Review}>
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                  <AiTwotoneStar />
                </div>
                <span>
                  The environment, learning and support provided by MSL is
                  impeccable. We endeavour to be amongst the most innovative in
                  the industry.
                </span>
                <h5>
                  Mr. Suyash Bhandari<span>Team Mindspright Legal</span>{" "}
                </h5>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default About_Us;
