import React, { useState } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  useNavigate,
} from "react-router-dom";
import Header from "../components/Header/TopHeader/Header";
import Home from "../components/Home/HomePage/Home";
import SecuritiesLaw from "../components/Home/CorePracticies/SecuritiesLaw";
import Our_Team from "../components/Home/Our_Team/Our_Team";
import LitigationDispute from "../components/Home/CorePracticies/LitigationDispute";
import CorporateLaw from "../components/Home/CorePracticies/CorporateLaw";
import WhiteCollarCrime from "../components/Home/CorePracticies/WhiteCollarCrime";
import IntellectualProperty from "../components/Home/CorePracticies/IntellectualProperty";
import TransactionAdvisory from "../components/Home/CorePracticies/TransactionAdvisory";
import Compliances from "../components/Home/CorePracticies/Compliances";
import OurPresence from "../components/Home/OurPresence/OurPresence";
import Careers from "../components/Home/Careers/Careers";
import { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import BizClass from "../components/Home/HomePage/Home.module.scss";
import Client_Banner from "../Imges/Client_banner.png";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      <Route
        index
        element={
          <React.Suspense fallback={<>...</>}>
            <Home />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/our-team"
        element={
          <React.Suspense fallback={<>...</>}>
            <Our_Team />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/securities-law"
        element={
          <React.Suspense fallback={<>...</>}>
            <SecuritiesLaw />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/litigation-dispute"
        element={
          <React.Suspense fallback={<>...</>}>
            <LitigationDispute />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/corporate-law"
        element={
          <React.Suspense fallback={<>...</>}>
            <CorporateLaw />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/compliances"
        element={
          <React.Suspense fallback={<>...</>}>
            <Compliances />
          </React.Suspense>
        }
      />

      <Route
        exact
        path="/intellectual-property"
        element={
          <React.Suspense fallback={<>...</>}>
            <IntellectualProperty />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/white-collar-crime"
        element={
          <React.Suspense fallback={<>...</>}>
            <WhiteCollarCrime />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/transaction-advisory"
        element={
          <React.Suspense fallback={<>...</>}>
            <TransactionAdvisory />
          </React.Suspense>
        }
      />
      {/* ////// Our Presence /////// */}
      <Route
        exact
        path="/our-presence"
        element={
          <React.Suspense fallback={<>...</>}>
            <OurPresence />
          </React.Suspense>
        }
      />
      <Route
        exact
        path="/careers"
        element={
          <React.Suspense fallback={<>...</>}>
            <Careers />
          </React.Suspense>
        }
      />
    </Route>
  )
);

export default router;

function RootLayout() {
  const [iagree, setIagree] = useState(true);
  const handleIagree = () => {
    setIagree(false);
  };
  return (
    <React.Fragment>
      <>
        {iagree ? (
          <div className={BizClass.Disclaimer_Section}>
            <div className={BizClass.Disclaimer}>
              <div className={BizClass.Header}>
                <h4>Disclaimer</h4>
                <img src={Client_Banner} alt="" />
              </div>
              <div className={BizClass.Content}>
                <span>
                  This website has been designed only for the purpose of
                  dissemination of basic information on Mindspright Legal. The
                  information provided under this website is solely available at
                  your request for informational purposes only and the same
                  should not be interpreted as soliciting or advertisement,
                  personal communication, invitation, or inducement of any sort,
                  whatsoever, from Mindspright Legal. Any information obtained
                  or materials downloaded from this website is completely at the
                  user’s volition and any transmission, receipt, or use of this
                  site or the information contained herein shall not create any
                  lawyer-client relationship.
                  <br />
                  We are not liable for any consequence of any action taken by
                  the user relying on material/information provided under this
                  website. In cases where the user has any legal issues, he/she
                  in all cases must seek independent legal advice. <br />
                  By clicking on ‘I AGREE’, the visitor acknowledges that the
                  information provided on this website (a) does not amount to
                  advertising or solicitation, and (b) is meant only for his/her
                  understanding of Mindspright Legal.
                </span>
              </div>
              <div className={BizClass.Footer}>
                <Link className={BizClass.button} to="/">
                  {" "}
                  <button onClick={handleIagree}>I Agree</button>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <Header />
        <Outlet />
      </>
    </React.Fragment>
  );
}
