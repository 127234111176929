import React, { useState } from "react";
import BizClass from "./Header.module.scss";
import { VscThreeBars } from "react-icons/vsc";
import { ImCross } from "react-icons/im";
import { AiFillCaretRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import Client_Banner from "../../../Imges/Client_banner.png";

const Header = (props) => {
  const [menu, setMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState("1");

  const openMenuBar = () => {
    setMenu(!menu);
  };

  const toggleMenu = (menu) => {
    setActiveMenu(menu.id);
    setMenu(false);
  };
  const toggleSubMenu = (Submenu) => {
    setActiveMenu(Submenu.id);
    setMenu(false);
  };
  const Menudata = [
    {
      id: "1",
      name: "Home",
      path: "/",
      SubMenu: [],
    },
    {
      id: "2",
      name: <div className={BizClass.CoreDiv}>Core Practices + </div>,
      path: "#",
      SubMenu: [
        {
          id: "2-1",
          name: " Securities Law",
          path: "/securities-law",
        },
        {
          id: "2-2",
          name: " Litigation & Dispute Resolution",
          path: "/litigation-dispute",
        },
        {
          id: "2-3",
          name: " Corporate Law",
          path: "/corporate-law",
        },
        {
          id: "2-4",
          name: "  Secretarial Compliances",
          path: "/compliances",
        },
        {
          id: "2-5",
          name: " White Collar Crime",
          path: "/white-collar-crime ",
        },
        {
          id: "2-6",
          name: " Intellectual Property",
          path: "/intellectual-property",
        },
        {
          id: "2-7",
          name: "   Transaction Advisory",
          path: "/transaction-advisory",
        },
      ],
    },
    {
      id: "3",
      name: "   Our Team",
      path: "/our-team",
      SubMenu: [],
    },
    {
      id: "4",
      name: "  Our Presence",
      path: "/our-presence",
      SubMenu: [],
    },
    {
      id: "5",
      name: "Careers",
      path: "/careers",
      SubMenu: [],
    },
  ];

  return (
    <div className={BizClass.Header}>
      <div className={BizClass.Client_Logo}>
        <img src={Client_Banner} alt="" />
      </div>
      <div className={BizClass.MenuListing}>
        <div className={BizClass.Lsit}>
          <ul>
            {Menudata.map((data, indx) => {
              return (
                <>
                  <div className={BizClass.Data_Link} key={indx}>
                    <div
                      className={BizClass.Menu}
                      onClick={() => toggleMenu(data)}
                    >
                      <Link className={BizClass.Menu} to={data.path}>
                        {data.name}
                      </Link>
                    </div>
                    <div
                      className={
                        activeMenu === data.id
                          ? BizClass.ActiveLink
                          : BizClass.link_Div
                      }
                    ></div>
                    <div className={BizClass.Sub_Menu}>
                      {data.SubMenu &&
                        data.SubMenu.map((Subdata, indx) => {
                          return (
                            <React.Fragment key={indx}>
                              <Link
                                className={
                                  activeMenu === Subdata.id
                                    ? BizClass.Menu
                                    : BizClass.Menu
                                }
                                onClick={() => toggleSubMenu(Subdata)}
                                to={Subdata.path}
                                key={indx}
                              >
                                {Subdata.name}
                              </Link>
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </div>
                </>
              );
            })}
          </ul>
        </div>
        <div className={BizClass.Search_Div}>
          <div>
            <input type="text" placeholder="Search" />
          </div>
        </div>
      </div>

      <div className={BizClass.MenuBar}>
        <VscThreeBars onClick={openMenuBar} />
        {menu ? (
          <div className={BizClass.Container}>
            <div className={BizClass.Section_Bar}>
              <ImCross onClick={openMenuBar} />
              <img src={Client_Banner} alt="" />
              <div className={BizClass.MenuConatiner}>
                {Menudata.map((data, indx) => {
                  return (
                    <>
                      <div className={BizClass.Data_Link} key={indx}>
                        <div
                          className={BizClass.Menu}
                          onClick={() => toggleMenu(data)}
                        >
                          <Link className={BizClass.MenuData} to={data.path}>
                            {data.name}
                          </Link>
                        </div>
                        <div
                          className={
                            activeMenu === data.id
                              ? BizClass.ActiveSubLink
                              : BizClass.sublink_Div
                          }
                        ></div>
                        <div className={BizClass.Sub_Menu}>
                          {data.SubMenu &&
                            data.SubMenu.map((Subdata, indx) => {
                              return (
                                <React.Fragment key={indx}>
                                  <Link
                                    className={
                                      activeMenu === Subdata.id
                                        ? BizClass.ActiveSubLink
                                        : BizClass.sublink_Div
                                    }
                                    onClick={() => toggleSubMenu(Subdata)}
                                    to={Subdata.path}
                                    key={indx}
                                  >
                                    {Subdata.name}
                                  </Link>
                                </React.Fragment>
                              );
                            })}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Header;
