import React ,{useState}from "react";
import "react-toastify/dist/ReactToastify.css";
import { RouterProvider } from "react-router-dom";
import router from "./routes/route";

function App() {

  return (
    <>
   
      <RouterProvider router={router} />
    </>
  );
}

export default App;
