import React from "react";
import Footer from "../Footer/Footer";
import BizClass from "./Our_team.module.scss";
import { BsTelephone } from "react-icons/bs";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import Team_New_One from "../../../Imges/Team-top-1.jpg";
import Team_New_Two from "../../../Imges/Team-top-2.jpg";
import Team_New_Three from "../../../Imges/Team-top-3.jpg";
import Team_New_Four from "../../../Imges/Team-top-4.jpg";
import Team_New_Five from "../../../Imges/Team-top-5.jpg";
import Team_New_Six from "../../../Imges/Team-top-6.jpg";
import Team_New_Eight from "../../../Imges/Team-top-8.jpg";
import Team_New_Nine from "../../../Imges/Team-top-9.jpg";
import Team_New_Ten from "../../../Imges/Team-top-7.png";
import Team_Bottom_One from "../../../Imges/Team-top-7.png";
import Team_Bottom_Two from "../../../Imges/Team-bottom-2.jpg";
import Team_Bottom_Three from "../../../Imges/Team-bottom-3.jpg";
import Team_Bottom_Four from "../../../Imges/Team-bottom-4.jpeg";
import Team_Bottom_Five from "../../../Imges/Team-bottom-5.jpeg";
import Team_Bottom_Six from "../../../Imges/Team-bottom-6.jpeg";
import Team_Bottom_Seven from "../../../Imges/Team-bottom-7.jpeg";
import Team_Bottom_Eight from "../../../Imges/Team-bottom-8.png";
import Team_Bottom_Nine from "../../../Imges/Team-bottom-9.png";
import Shashwat from "../../../Imges/Shashwat.png";
import Team_Bottom_Eleven from "../../../Imges/Team-bottom-11.jpg";
import Team_Bottom_Twelve from "../../../Imges/Team-bottom-12.jpg";
import Team_Bottom_Thirteen from "../../../Imges/Team-bottom-13.jpg";

const Our_Team = () => {
  const teamCard = [
    {
      img: <img src={Team_New_One} alt="" />,
      name: "Aditya Bhansali",
      post: "FOUNDER & PARTNER",
      data: (
        <div>
          {" "}
          <b>Qualification :</b> B.Com. (Hons.), LL. B, M.B.A., M.B.L <br />
          <b>Specialisation : </b>
          Securities Law,Corporate /Business Structuring and Restructuring,
          White Collar Crimes and Insolvency & Bankruptcy Code"{" "}
        </div>
      ),
      email: "📧 aditya@mindspright.co.in",
      phone: (
        <div>
          {" "}
          <BsTelephone /> +91- 69000 67000, +91- 93242 20250 <br />{" "}
          <TbDeviceLandlinePhone /> +91- 22- 42197001, +91- 22- 42197009{" "}
        </div>
      ),
    },
    {
      img: <img src={Team_New_Two} alt="" />,
      name: "Akshaya Bhansali",
      post: " MANAGING PARTNER",
      data: (
        <div>
          {" "}
          <b>Qualification :</b> B.B.A, LL. B (Hons.) <br />{" "}
          <b>Specialisation :</b> Securities Law, Mergers & Acquisition{" "}
        </div>
      ),
      email: "📧 akshaya@mindspright.co.in",
      phone: (
        <div>
          {" "}
          <BsTelephone />
          +91- 98670 30250
          <br /> <TbDeviceLandlinePhone />
          +91- 22- 42197002{" "}
        </div>
      ),
    },

    {
      img: <img src={Team_New_Five} alt="" />,
      name: "Nirali Mehta",
      post: "PARTNER",
      data: (
        <div>
          {" "}
          <b>Qualification :</b> B.Com., LL.B., LL.M.(corporate & Finance){" "}
          <br /> <b>Specialisation :</b>Corporate law, Securities Law,
          Insolvency, Bankruptcy & Compliances
        </div>
      ),
      email: " nirali@mindspright.co.in ",
      phone: (
        <div>
          {" "}
          <BsTelephone />
          +91- 9819733214
          <br /> <TbDeviceLandlinePhone />
          +22- 42197005
        </div>
      ),
    },

    {
      img: <img src={Team_New_Three} alt="" />,
      name: "Satish Chandra Purohit",
      post: "PARTNER",
      data: (
        <div>
          {" "}
          <b>Qualification :</b> B.Sc., LL.B, D.C.L.L <br />{" "}
          <b>Specialisation :</b> General Litigation,{" "}
        </div>
      ),
      email: "📧satish@mindspright.co.in",
      phone: (
        <div>
          {" "}
          <BsTelephone />
          +91- 94146 89716
          <br /> <TbDeviceLandlinePhone />
          +91- 141 - 2226996{" "}
        </div>
      ),
    },
    {
      img: <img src={Team_New_Four} alt="" />,
      name: " Richa Bhansali  ",
      post: " PARTNER",
      data: (
        <div>
          {" "}
          <b>Qualification : </b> B.Com. LL.B. <br /> <b>Specialisation :</b>{" "}
          Corporate Law
        </div>
      ),
      email: " 📧 richa@mindspright.co.in",
      phone: (
        <div>
          {" "}
          <BsTelephone />
          +91- 99994 69381
          <br /> <TbDeviceLandlinePhone />
          +91- 11- 49875050{" "}
        </div>
      ),
    },

    {
      img: <img src={Team_New_Six} alt="" />,
      name: "Rahul Kumar Das",
      post: " PARTNER",
      data: (
        <div>
          {" "}
          <b>Qualification :</b> B.Com, LL.B. <br /> <b>Specialisation :</b>{" "}
          Civil, Criminal, Revenue, Consumer,Constitution and Service Law
        </div>
      ),
      email: " 📧 rahul@mindspright.co.inn",
      phone: (
        <div>
          {" "}
          <BsTelephone />
          +91- 72502 55133/ 74889 17465
        </div>
      ),
    },
    {
      img: <img src={Team_New_Eight} alt="" />,
      name: "PUSHPENDRA PRATAP SINGH",
      post: " PARTNER",
      data: (
        <div>
          {" "}
          <b> Qualification :</b> B. Sc., PCS <br /> <b>Specialisation :</b>{" "}
          Corporate law, Securities law,Insolvency, Bankruptcy & Compliances{" "}
        </div>
      ),
      email: " 📧 pushpendra@mindspright.co.in",
      phone: (
        <div>
          {" "}
          <BsTelephone />
          +91- 22- 42197003
          <br /> <TbDeviceLandlinePhone />
          +91- 22- 42197007{" "}
        </div>
      ),
    },
    {
      img: <img src={Team_New_Nine} alt="" />,
      name: "PRIYAVRAT GUPTA",
      post: " PARTNER",
      data: (
        <div>
          {" "}
          <b>Qualification :</b> CA <br /> <b>Specialisation :</b> Direct and
          Indirect taxes litigation and Transaction & forensic audits for
          companies for Pre-emptive measures before government departments
        </div>
      ),
      email: " 📧 capriyavrat@mindspright.co.in",
      phone: (
        <div>
          {" "}
          <BsTelephone />
          +91- 88791 00437
          <br /> <TbDeviceLandlinePhone />
          +91- 22- 42197004
        </div>
      ),
    },
    {
      img: <img src={Shashwat} alt="" />,
      name: "Shashwat Purohit",
      post: " PARTNER",
      data: (
        <div>
          {" "}
          <b>Qualification :</b> B.A., LL.B, LLM (IPR) <br />{" "}
          <b>Specialisation :</b> Intellectual Property Rights, General
          Litigation, Insolvency & Bankruptcy Code{" "}
        </div>
      ),
      email: " 📧  shashwat@mindspright.co.in",
      phone: (
        <div>
          {" "}
          <BsTelephone />
          +91- 99297 71987, +91- 98292 29322
          <br /> <TbDeviceLandlinePhone />
          +91- 141 - 2226996
        </div>
      ),
    },
    // {
    //   img: <img src={Team_New_Ten} alt="" />,
    //   name: "ABHISHEK",
    //   post: " PARTNER",
    //   data: (
    //     <div>
    //       {" "}
    //       <b>Qualification :</b> B.A. LL.B. <br /> <b>Specialisation : </b>{" "}
    //       General Corporate and Commercial Litigation, Insolvency & Bankruptcy
    //       Code, Securities Law & Arbitration and Dispute Resolution
    //     </div>
    //   ),
    //   email: " 📧   abhishek@mindspright.co.in",
    //   phone: (
    //     <div>
    //       {" "}
    //       <BsTelephone />
    //       +91- 70168 16910
    //       <br /> <TbDeviceLandlinePhone />
    //       +91- 11 - 49875050, +91- 11 - 23724533
    //     </div>
    //   ),
    // },
  ];

  const subCard = [
    // {
    //   img: <img src={Team_Bottom_One} alt="" />,
    //   name: "Mohit",
    //   email: "📧 mohit@mindspright.co.in",
    // },
    {
      img: <img src={Team_Bottom_Two} alt="" />,
      name: "Suyash Bhandari",
      email: "📧 suyash@mindspright.co.in",
    },
    {
      img: <img src={Team_Bottom_Three} alt="" />,
      name: "Jay Vardhan",
      email: "📧  JayVardhan@mindspright.co.in",
    },
    {
      img: <img src={Team_Bottom_Four} alt="" />,
      name: "Aditya Thanvi",
      email: "📧adityathanvi@mindspright.co.in",
    },
    {
      img: <img src={Team_Bottom_Five} alt="" />,
      name: "Supriya Nair",
      email: "📧 supriya@mindspright.co.in",
    },
    {
      img: <img src={Team_Bottom_Six} alt="" />,
      name: "Dimple Gyamlani",
      email: "📧dimple@mindspright.co.in",
    },
    {
      img: <img src={Team_Bottom_Seven} alt="" />,
      name: "Pulkit Lodha",
      email: "📧pulkit@mindspright.co.in",
    },
    {
      img: <img src={Team_Bottom_Nine} alt="" />,
      name: "Sarthak Guru",
      email: "📧sarthak@mindspright.co.in",
    },
    // {
    //   img: <img src={Team_Bottom_Ten} alt="" />,
    //   name: "Priyank Kumar Saxena",
    //   email: "📧priyank@mindspright.co.in",
    // },
    {
      img: <img src={Team_Bottom_Eight} alt="" />,
      name: "Utkarsh Raj",
      email: "📧utkarshraj@mindspright.co.in",
    },
    {
      img: <img src={Team_Bottom_Eleven} alt="" />,
      name: "Shubham Vishwakarma",
      email: "📧secretarial@mindspright.co.in",
    },
    {
      img: <img src={Team_Bottom_Twelve} alt="" />,
      name: "Sayalee Gaikwad",
      email: "📧compliance@mindspright.co.in",
    },
    {
      img: <img src={Team_Bottom_Thirteen} alt="" />,
      name: "PCS Mayuri Thakkar",
      email: "📧mayuri@mindspright.co.in",
    },
  ];
  return (
    <div className="Biz_PageStart">
      <div className={BizClass.Our_Team}>
        <div className={BizClass.Section_Img}>
          <h4>Our Team</h4>
        </div>
        <span>Meet our Founding Partners</span>
        <p>
          The firm’s success stems from our lawyers’ competence, their
          collaborative <br /> strengths, and our ability to build
          relationships.
        </p>
        <div className={BizClass.Team_Card_Section}>
          {teamCard.map((team, index) => {
            return (
              <>
                <div className={BizClass.Card} key={index}>
                  <div className={BizClass.img}>{team.img}</div>
                  <div className={BizClass.Content}>
                    <h4>{team.name}</h4>
                    <span>{team.post}</span>
                    <p>{team.data}</p>
                    <p>{team.email}</p>
                    <p>{team.phone}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className={BizClass.SubCard_Section}>
          {subCard.map((data, index) => {
            return (
              <>
                <div className={BizClass.Card}>
                  <div className={BizClass.img}>{data.img}</div>
                  <span>{data.name}</span>
                  <p>{data.email}</p>
                </div>
              </>
            );
          })}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Our_Team;
