import React from "react";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaLinkedin,
} from "react-icons/fa";
import BizClass from "./Footer.module.scss";
import { Link } from "react-router-dom";
import Client_Banner from "../../../Imges/Client_banner.png";

const Footer = () => {
  return (
    <div className={BizClass.Footer}>
      <div className={BizClass.Section_Footer}>
        <div className={BizClass.data_Div}>
          <img src={Client_Banner} alt="" />
        </div>
        <div className={BizClass.data_Div}>
          <h4>Links</h4>
          <ul>
            <li>
              <Link to="/" className={BizClass.Office_Span}>
              Home
              </Link>
            </li>
            <li>
              <Link to="/our-team" className={BizClass.Office_Span}>
                Our Team
              </Link>
            </li>
            <li>
              <Link to="/our-presence" className={BizClass.Office_Span}>
                Our Presence
              </Link>
            </li>
            <li>
              <Link to="/careers" className={BizClass.Office_Span}>
                Careers
              </Link>
            </li>
          </ul>
        </div>
        <div className={BizClass.data_Div}>
          <h4>Offices</h4>
          <span>
            <Link to="/our-presence" className={BizClass.Office_Span}>
              Mumbai
            </Link>

            <Link to="/our-presence" className={BizClass.Office_Span}>
              Delhi
            </Link>

            <Link to="/our-presence" className={BizClass.Office_Span}>
              Jaipur
            </Link>

            <Link to="/our-Presence" className={BizClass.Office_Span}>
              Ranchi
            </Link>
          </span>
        </div>
        <div className={BizClass.data_Div}>
          <h4>Follow us</h4>
          <div>
            <div className={BizClass.Social_Logo}>
              <FaFacebookSquare />
              <FaTwitterSquare />
              <FaInstagramSquare />
              <FaLinkedin />
            </div>
            <p>legal@mindspright.co.in</p>
          </div>
        </div>
      </div>
      <div className={BizClass.Sub_Footer}>
        <span>© 2023 Mindspright Legal.</span>
        <div className={BizClass.Policyes}>
          <span>Sitemap </span>
          <span>Privacy Policy</span>
          <span>Cookie Policy</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
