import React, { useState } from "react";
import BizClass from "./CorePracticies.module.scss";
import { FaAngleRight } from "react-icons/fa";
import Footer from "../Footer/Footer";
import { HiChevronDoubleRight } from "react-icons/hi";
import CorePracticiesSlider from "./CorePracticiesSlider/CorePracticiesSlider";
import { Link } from "react-router-dom";
const WhiteCollarCrime = (props) => {
  const [activeMenu, setActiveMenu] = useState("5");
  return (
    <div className="Biz_PageStart">
      <div className={BizClass.White_Section_Img}>
        <h4>White Collar Crime</h4>
      </div>
      <div className={BizClass.Section_Core}>
        <div className={BizClass.Section_Div}>
        <div className={BizClass.Links_Contant}>
            <Link
              className={
                activeMenu === "1" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Securities-law"
              onClick={() => setActiveMenu("1")}
            >
              Securities Law
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "2" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Litigation-Dispute"
              onClick={() => setActiveMenu("2")}
            >
              Litigation & Dispute Resolution
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "3" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/corporate-law"
              onClick={() => setActiveMenu("3")}
            >
              Corporate Law
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "4" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Compliances"
              onClick={() => setActiveMenu("4")}
            >
              Secretarial Compliances
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "5" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/white-collar-crime"
              onClick={() => setActiveMenu("5")}
            >
              White Collar Crime
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "6" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/intellectual-property"
              onClick={() => setActiveMenu("6")}
            >
              Intellectual Property
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "7" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/transaction-advisory"
              onClick={() => setActiveMenu("7")}
            >
              Transaction Advisory
              <FaAngleRight />
            </Link>
          </div>
          <div className={BizClass.Data_Contant}>
            <p>
              With the rise in White Collar crimes which largely stem from
              Corporate Laws and Securities Laws practices, we at MSL felt a
              need for a separate wing to handle such cases. Our lawyers with
              their extensive knowledge and experience are well versed in the
              laws and practices relating to White-Collar crimes.
            </p>
            <h4>Some of the practices of this group are:</h4>
            <div className={BizClass.Content_Div}>
              <div>
                <HiChevronDoubleRight />
                Advising clients in relation to matters related to corporate and
                other frauds, criminal breach of trust, money laundering,
                defamation, data theft, etc.
              </div>
              <div>
                <HiChevronDoubleRight />
                Representing clients before courts and other fora for matters
                relating to civil and criminal litigation arising within the
                scope of White-Collar crimes.
              </div>

              <div>
                <HiChevronDoubleRight />
                Conducting due diligence for companies and individuals to be
                aligned with various policies, laws and regulations within the
                sphere of White-Collar crimes.
              </div>
              <div>
                <HiChevronDoubleRight />
                Assisting clients in investigations and trials relating to
                matters before ED, SEBI, CBI, SFIO, MCA, and other fora.
              </div>
            </div>
          </div>
        </div>
        <div className={BizClass.Slider_Div}>
          <CorePracticiesSlider />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WhiteCollarCrime;
