import React, { useState } from "react";
import BizClass from "./CorePracticies.module.scss";
import { FaAngleRight } from "react-icons/fa";
import Footer from "../Footer/Footer";
import { HiChevronDoubleRight } from "react-icons/hi";
import CorePracticiesSlider from "./CorePracticiesSlider/CorePracticiesSlider";
import { Link } from "react-router-dom";
const Compliances = (props) => {
  const [activeMenu, setActiveMenu] = useState("4");
  return (
    <div className="Biz_PageStart">
      <div className={BizClass.Section_Img}>
        <h4> Secretarial Compliances</h4>
      </div>
      <div className={BizClass.Section_Core}>
        <div className={BizClass.Section_Div}>
          <div className={BizClass.Links_Contant}>
            <Link
              className={
                activeMenu === "1" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Securities-law"
              onClick={() => setActiveMenu("1")}
            >
              Securities Law
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "2" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Litigation-Dispute"
              onClick={() => setActiveMenu("2")}
            >
              Litigation & Dispute Resolution
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "3" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/corporate-law"
              onClick={() => setActiveMenu("3")}
            >
              Corporate Law
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "4" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/compliances"
              onClick={() => setActiveMenu("4")}
            >
              Secretarial Compliances
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "5" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/white-collar-crime"
              onClick={() => setActiveMenu("5")}
            >
              White Collar Crime
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "6" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/intellectual-property"
              onClick={() => setActiveMenu("6")}
            >
              Intellectual Property
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "7" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/transaction-advisory"
              onClick={() => setActiveMenu("7")}
            >
              Transaction Advisory
              <FaAngleRight />
            </Link>
          </div>
          <div className={BizClass.Data_Contant}>
            <p>
              MSL provides integrated services to its clients in the field of
              Secretarial Compliances. Having experience in securities law, we
              aim to provide consolidated solutions to our clients/ MSL has
              uniquely positioned itself aiming at providing consolidated
              solution to its clients
            </p>
            <h4>Some of our key services include the following:</h4>
            <div className={BizClass.Content_Div}>
              <div>
                <HiChevronDoubleRight />
                Advising and representing clients at the start investigation
                inspection MSEI, NCDEX, carried out by various exchanges (BSE,
                NSE, MCX etc.) on their members and intermediaries;
              </div>
              <div>
                <HiChevronDoubleRight />
                Handling litigation up to the Appellate stage at the Supreme
                Court for cases related Capital Markets those stemming operating
                markets like the commodities market and the currency market
              </div>

              <div>
                <HiChevronDoubleRight />
                Appearing before the Securities Appellate Tribunal (SAT) having
                its only bench in Mumbai as well as for matters before Special
                Court and other Courts;
              </div>
              <div>
                <HiChevronDoubleRight />
                Proceedings at SEBI including the Ex-Parte Orders, Adjudication
                Proceedings, Enquiry Proceedings, Settlement Proceedings, 11B
                Proceedings and others;
              </div>
              <div>
                <HiChevronDoubleRight />
                Handling procedures related to Investor Grievances, Conciliatory
                Proceeding, and Proceeding Stock Negotiating Arbitration at
                various Exchanges, Depositories etc.
              </div>
            </div>
          </div>
        </div>
        <div className={BizClass.Slider_Div}>
          <CorePracticiesSlider />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Compliances;
