import React, { useState } from "react";
import BizClass from "./CorePracticies.module.scss";
import { FaAngleRight } from "react-icons/fa";
import Footer from "../Footer/Footer";
import { HiChevronDoubleRight } from "react-icons/hi";
import CorePracticiesSlider from "./CorePracticiesSlider/CorePracticiesSlider";
import { Link } from "react-router-dom";
const CorporateLaw = () => {
  const [activeMenu, setActiveMenu] = useState("3");
  return (
    <div className="Biz_PageStart">
      <div className={BizClass.Corporate_Section_Img}>
        <h4>Corporate Law</h4>
      </div>
      <div className={BizClass.Section_Core}>
        <div className={BizClass.Section_Div}>
        <div className={BizClass.Links_Contant}>
            <Link
              className={
                activeMenu === "1" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Securities-law"
              onClick={() => setActiveMenu("1")}
            >
              Securities Law
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "2" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Litigation-Dispute"
              onClick={() => setActiveMenu("2")}
            >
              Litigation & Dispute Resolution
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "3" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/corporate-law"
              onClick={() => setActiveMenu("3")}
            >
              Corporate Law
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "4" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/Compliances"
              onClick={() => setActiveMenu("4")}
            >
              Secretarial Compliances
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "5" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/white-collar-crime"
              onClick={() => setActiveMenu("5")}
            >
              White Collar Crime
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "6" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/intellectual-property"
              onClick={() => setActiveMenu("6")}
            >
              Intellectual Property
              <FaAngleRight />
            </Link>
            <Link
              className={
                activeMenu === "7" ? BizClass.Active_Core : BizClass.Menu_Core
              }
              to="/transaction-advisory"
              onClick={() => setActiveMenu("7")}
            >
              Transaction Advisory
              <FaAngleRight />
            </Link>
          </div>
          <div className={BizClass.Data_Contant}>
            <p>
              At MSL our effort is to support the entire life cycle of a company
              and all of its related transactions right from the initial stages
              to the post-closing stages.
            </p>
            <h4>Some of the services provided by this group are as follows:</h4>
            <div className={BizClass.Content_Div_Two}>
              <div>
                <HiChevronDoubleRight />
                Drafting, opinions and interpretation of corporate laws and
                policies.
              </div>
              <div>
                <HiChevronDoubleRight />
                Assisting Merchant Bankers and companies in drafting replies to
                the queries that SEBI raises.
              </div>
              <div>
                <HiChevronDoubleRight />
                Arguing on behalf of our clients in all proceedings before the
                National Company Law Tribunal (NCLT & NCLAT) and all other
                authorities and fora.
              </div>
              <div>
                <HiChevronDoubleRight />
                Advising its clients for Delisting and Winding Up through
                various modes.
              </div>
              <div>
                <HiChevronDoubleRight />
                Advising Corporates and Investment Bankers regarding various
                capital market transactions as Public Offerings, Preferential
                Issues, Private Investments, Foreign Direct Investments,
                Structuring and regulatory advice.
              </div>
            </div>
          </div>
        </div>
        <div className={BizClass.Slider_Div}>
          <CorePracticiesSlider />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CorporateLaw;
